import React from "react";

export default function Team() {
  return (
    <div>
      <p className="md:text-[44px] text-[30px] font-bold md:mt-[142px] mt-[20px] text-center text-white">
        Meet Our Team
      </p>
      <div className="max-w-[969px]  m-auto md:mt-20 mt-5">
        <div className="grid sm:grid-cols-3 md:gap-8 gap-4">
          <div className="bg-[#C4C4C41A] rounded-lg border-2 border-[#383666] p-[20px]  w-[100%]">
            <img src="/image-5.png" alt="" className="w-[100%]" />
            <p className="font-bold text-[24px] text-white text-center mt-[22px]">
              RedMan Terra
            </p>
            <p className="font-bold text-[18px] text-[#B8B7C8] text-center mt-[1px]">
              Founder
            </p>
            <div className="flex justify-center mt-4">
              <img src="/Vector.svg" alt="" className="mr-5" />
              <img src="/Vector-1.svg" alt="" />
            </div>
          </div>
          <div className="bg-[#C4C4C41A] rounded-lg border-2 p-[20px] border-[#383666] w-[100%]">
            <img src="/image-6.png" alt="" className="w-[100%]" />
            <p className="font-bold text-[24px] text-white text-center mt-[22px]">
              0xZenica
            </p>
            <p className="font-bold text-[18px] text-[#B8B7C8] text-center mt-[1px]">
              CMO
            </p>
            <div className="flex justify-center mt-4">
              <img src="/Vector.svg" alt="" className="mr-5 cursor-pointer" />
              <img src="/Vector-1.svg" alt="" className="cursor-pointer" />
            </div>
          </div>
          <div className="bg-[#C4C4C41A] rounded-lg border-2 p-[20px] border-[#383666] w-[100%]">
            <img src="/image-7.png" alt="" className="w-[100%]" />
            <p className="font-bold text-[24px] text-white text-center mt-[22px]">
              0xJackpot
            </p>
            <p className="font-bold text-[18px] text-[#B8B7C8] text-center mt-[1px]">
              Graphics Design
            </p>
            <div className="flex justify-center mt-4">
              <img src="/Vector.svg" alt="" className="mr-5" />
              <img src="/Vector-1.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
