import React, { useEffect, useState } from "react";
import Drawer from "../drawer";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  console.log("location.pathname", location.pathname);

  return (
    <div className="xl:px-[60px]  px-[10px] sm:py-[25px] py-[10px] border-[#383666] border">
      <div className="flex justify-between items-center ">
        <img src="/logo.svg" alt="" />
        <div className="lg:flex cursor-pointer  hidden justify-between items-center w-[100%] xl:text-[18px] text-[14px] xl:px-[60px]  px-[20px]">
          <p
            className={`font-semibold  ${
              location.pathname === "/" ? "text-[#F206C2]" : "text-white"
            }`}
            onClick={() => navigate("/")}
          >
            Home
          </p>
          <p
            className={`font-semibold  ${
              location.pathname === "/about-us"
                ? "text-[#F206C2]"
                : "text-white"
            }`}
            onClick={() => navigate("/about-us")}
          >
            About us
          </p>
          <p
            className={`font-semibold  ${
              location.pathname === "/launch-pad"
                ? "text-[#F206C2]"
                : "text-white"
            }`}
            onClick={() => navigate("/launch-pad")}
          >
            Launchpad
          </p>
          <p
            className={`font-semibold  ${
              location.pathname === "/discover"
                ? "text-[#F206C2]"
                : "text-white"
            }`}
            onClick={() => navigate("/discover")}
          >
            Discover Airdrops
          </p>
          <p className="font-semibold cursor-pointer text-white">BUY NARROW</p>
          <p className="font-semibold cursor-pointer text-white">CHART</p>
        </div>
        <div
          onClick={toggleMenu}
          className="h-[54px] lg:flex  hidden cursor-pointer text-[20px] max-w-[228px] w-[100%] bg-[#F206C2] rounded-full font-semibold   justify-center items-center text-white"
        >
          Connect Wallet
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-8 h-8 text-white lg:hidden "
          onClick={toggleDrawer}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>
      </div>
      {isOpen && (
        <div className="relative">
          {isOpen && (
            <div
              className="fixed inset-0 z-10 bg-black opacity-50"
              onClick={toggleMenu}
            ></div>
          )}
          <div
            className={`absolute bg-[#F206C2] z-20 p-[40px] max-w-[394px] w-[100%] rounded-lg shadow-md top-[0px] right-[0px] ${
              isOpen ? "" : "hidden"
            }`}
          >
            <div className="flex justify-between">
              <p className="font-semibold text-[20px] text-white">
                Connect Wallet
              </p>{" "}
              <div
                className="w-[23px] h-[23px] rounded-full bg-[white] cursor-pointer flex justify-center items-center"
                onClick={toggleMenu}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-3 h-3"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </div>
            </div>
            <div className="w-[100%] py-[24px] px-[32px] bg-white rounded-2xl">
              <p className="font-bold">Choose your preferred wallet</p>
              <div className="flex justify-between mt-[29px]">
                <p className="font-bold text-[20px]">Rainbow</p>{" "}
                <img src="/1.png" alt="" />
              </div>
              <div className="flex justify-between mt-[29px]">
                <p className="font-bold text-[20px]">MetaMask</p>{" "}
                <img src="/2.png" alt="" />
              </div>
              <div className="flex justify-between mt-[29px]">
                <p className="font-bold text-[20px]">Gnosis Safe</p>{" "}
                <img src="/3.png" alt="" />
              </div>
              <div className="flex justify-between mt-[29px]">
                <p className="font-bold text-[20px]">Argent</p>{" "}
                <img src="/4.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      )}
      <Drawer isOpen={isDrawerOpen} onClose={toggleDrawer} />
    </div>
  );
}
