import React from "react";

export default function OurMission() {
  return (
    <div>
      <p className="text-white font-bold md:text-[54px] text-[30px] text-center md:mt-28 uppercase">
        Our Mission
      </p>

      <div className="grid grid-cols-12 lg:gap-20 md:gap-10 gap-5 md:mt-16 mt-5">
        <div className="sm:col-span-5 col-span-12">
          <div className="bg-[#C4C4C41A] rounded-lg p-[15px] border-2 border-[#383666]">
            <img src="/seating-1.png" alt="" className="w-full" />
          </div>
        </div>
        <div className="sm:col-span-7 col-span-12 flex justify-center items-center">
          <div className="max-w-[650px] m-auto">
            <p className="text-white md:text-[45px] text-[30px] md:leading-[48px] leading-[30px] font-[600] mt-4">
              Bridging Privacy and Innovation
            </p>
            <p className="text-white md:mt-10 mt-5">
              At Narrow Protocol, our mission is to revolutionize the crypto
              space by providing a secure and private platform for users to
              engage in Initial DEX Offerings (IDOs). We believe in empowering
              individuals to explore the vast world of crypto without
              compromising their privacy.
            </p>
            <div className="h-[50px] cursor-pointer text-[20px] max-w-[184px] md:mt-[84px] mt-[20px] w-[100%] bg-[#F206C2] rounded-full font-semibold  flex justify-center items-center text-white mr-5">
              Learn More
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
