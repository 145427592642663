import React from "react";

export default function HowWeAre() {
  return (
    <div>
      <p className="text-white font-bold md:text-[54px] text-[30px] text-center md:mt-[250px] mt-[30px] uppercase">
        Who We Are
      </p>

      <div className="grid grid-cols-12 lg:gap-20 md:gap-10 gap-5 mt-16">
        <div className="col-span-12 sm:hidden">
          <div className="bg-[#C4C4C41A] rounded-lg p-[15px] border-2 border-[#383666]">
            <img src="/seating-3.png" alt="" className="w-full" />
          </div>
        </div>
        <div className="sm:col-span-7 col-span-12 flex justify-center items-center">
          <div className="max-w-[650px]">
            <p className="text-white md:text-[45px] text-[30px] md:leading-[48px] leading-[30px] font-[600] mt-4">
              The Team Behind Narrow Protocol
            </p>
            <p className="text-white md:mt-10 mt-5">
              Meet the passionate minds driving innovation at Narrow Protocol.
              Our team comprises experienced professionals in blockchain
              technology, privacy solutions, and user experience. Together, we
              are dedicated to creating a launchpad that redefines how users
              participate in crypto projects.
            </p>
            <div className="h-[50px] cursor-pointer text-[20px] max-w-[184px] md:mt-[84px] mt-[20px] w-[100%] bg-[#F206C2] rounded-full font-semibold  flex justify-center items-center text-white mr-5">
              Learn More
            </div>
          </div>
        </div>
        <div className="col-span-5 sm:block hidden">
          <div className="bg-[#C4C4C41A] rounded-lg p-[15px] border-2 border-[#383666]">
            <img src="/seating-3.png" alt="" className="w-full" />
          </div>
        </div>
      </div>
    </div>
  );
}
