import React, { useState } from "react";


export default function Footer() {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    });
  };

  const SOLTokenAddress = "HXpSqLTparf57H1SsAcLBx3p7mJBWgadDBeegdLgcESE";

  return (
    <div className="md:mt-[160px] mt-[50px] ">
      <div className="text-center">
        <p className="text-[16px] sm:text-[18px] text-white">
          SOL Token Contract Address:
          <span className="mx-[10px]">{SOLTokenAddress}</span>
          <button
            onClick={() => copyToClipboard(SOLTokenAddress)}
            className={`text-[14px] sm:text-[16px] font-bold ${
              isCopied ? "text-green-500" : "text-blue-500"
            }`}
          >
            {isCopied ? "Copied!" : "Copy"}
          </button>
        </p>
      </div>
      <img src="/logo.svg" alt="" className="m-auto" />
      <p className="text-[24px] font-bold text-[#8285a4] text-center mt-[10px]">
        Contact Us
      </p>
      <p className="sm:text-[24px] text-[16px] font-bold text-white text-center sm:whitespace-normal whitespace-nowrap">
        hello@narrowprotocol.com
      </p>
      <div className="pb-[66px] border-b">
        <div className="max-w-[514px] m-auto sm:flex block justify-between items-center text-white ">
          <p
	    onClick={() => window.open(process.env.PUBLIC_URL + '/NARROW_PROTOCOL_13_2_24.pdf')}
	    className="text-[18px] sm:text-start text-center sm:mt-0 mt-2 cursor-pointer"
	    >
            Whitepaper
          </p>

          <div className="w-[8px] h-2 rounded-full bg-white md:block hidden"></div>
          <p
            onClick={() => window.open("https://dexview.com")}
            className="text-[18px]  sm:mt-0 mt-2 sm:text-start text-center cursor-pointer"
          >
            Dextools
          </p>
          <div className="w-[8px] h-2 rounded-full bg-white md:block hidden"></div>
          <p
            onClick={() => window.open("https://x.com/NarrowProtocol")}
            className="text-[18px]  sm:mt-0 mt-2 sm:text-start text-center cursor-pointer"
          >
            Twitter
          </p>
          <div className="w-[8px] h-2 rounded-full bg-white md:block hidden"></div>
          <p
            onClick={() => window.open("https://t.me/NarrowProtocol")}
            className="text-[18px]  sm:mt-0 mt-2 sm:text-start text-center cursor-pointer"
          >
            Telegram
          </p>
        </div>
      </div>
      <div className="flex justify-between py-[28px] text-white  ">
        <p className="text-[14px]">Disclaimer</p>
        <p className="text-[14px]">Copyright © narrowprotocol</p>
      </div>
    </div>
  );
}
