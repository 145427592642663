import React from "react";

export default function JoinTelegram() {
  return (
    <div>
      <div className="md:mt-[300px] mt-[50px] max-w-[1140px] w-[100%] m-auto md:pt-[75px] pt-[20px] md:pb-[69px] pb-[20px] bg-[#C4C4C41A] rounded-lg border-2 border-[#383666]">
        <p className="text-[#BABDD0] text-[20px] tracking-[10px] text-center uppercase">
          Join us now
        </p>
        <p className="md:text-[32px] text-[25px] font-bold text-white text-center mt-9 uppercase">
          Join our telegram now for latest updates
        </p>
        {/* <a href="https://t.me/NarrowProtocol" target="__blank"> */}
        <div
          onClick={() => window.open("https://t.me/NarrowProtocol")}
          className=" mt-10 cursor-pointer w-[192px] h-[50px] flex justify-center items-center border border-[#306FE9] rounded-md text-white font-[600] m-auto bg-[#303a74]"
        >
          Join Telegram
        </div>
        {/* </a> */}
      </div>
    </div>
  );
}
