import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function IDOS({ IDOSArray, title }) {
  const sliderRef = useRef(null);
  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1324,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };
  return (
    <div>
      <div className="font-semibold md:text-[54px] text-[30px] text-center text-white uppercase md:mt-14 mt-5">
        {title}
      </div>
      <div className="grid grid-cols-1 md:mt-14 mt-5 gap-5">
        <Slider {...settings} ref={sliderRef}>
          {IDOSArray.map((item) => (
            <div
              className=" lg:!w-[300px] sm:!w-[300px] py-[15px] px-[20px] bg-[#211448] rounded-2xl"
              style={{ backdropFilter: "blur(5px)" }}
            >
              <img src={item?.img} alt="" className="w-[100%]" />
              <div className="px-[12px]">
                <p className="font-bold text-[16px] mt-3 text-white">
                  {item.title}
                </p>
                <div className="flex justify-between items-center mt-[14px] text-[12px]">
                  <p className="font-bold text-white">Floor price:</p>{" "}
                  <p className="text-[#60ff00] font-semibold">26.64%</p>
                </div>
                <div className="flex justify-between items-center mt-[14px] text-[12px]">
                  <div className="flex">
                    <img src="/ellips-1.svg" alt="" />
                    <p className="text-white">16.7</p>
                  </div>
                  <div className="flex">
                    <img src="/ellips-1.svg" alt="" />
                    <p className="text-white">16.7</p>
                  </div>
                </div>
                <div className="w-[188px] h-[39px] bg-[#F206C2] rounded-full m-auto mt-8 flex justify-center items-center">
                  <p className="text-white font-bold text-[14px]">Learn More</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 text-white ml-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                    />
                  </svg>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="max-w-[330px] flex justify-between items-center m-auto  md:mt-28 mt-5">
        <div
          onClick={handlePrev}
          className="sm:w-[53px] w-[40px] sm:h-[53px] h-[40px] rounded-full border border-[#F206C2] cursor-pointer flex justify-center items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5 8.25 12l7.5-7.5"
            />
          </svg>
        </div>
        <p className=" font-bold text-white">VIEW ALL</p>
        <div
          onClick={handleNext}
          className="sm:w-[53px] w-[40px] sm:h-[53px] h-[40px] rounded-full border border-[#F206C2] cursor-pointer flex justify-center items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}
