import React from "react";
import Header from "../../components/header";
import IDOS from "../../components/IDOS";
import HowItWork from "../../components/howItWork";
import Partners from "../../components/partners";
import Team from "../../components/team";
import FAQ from "../../components/faq";
import JoinTelegram from "../../components/joinTelegram";
import Footer from "../../components/footer";

export default function LounchPad() {
  const IDOSArray = [
    {
      img: "/img-1.png",
      title: "Crypto Punks profile image",
    },
    {
      img: "/img-2.png",
      title: "Crypto Punks profile image",
    },
    {
      img: "/img-3.png",
      title: "Crypto Punks profile image",
    },
    {
      img: "/img-4.png",
      title: "Crypto Punks profile image",
    },
  ];
  return (
    <div>
      <div className="bg-no-repeat bg-cover launchpad">
        <div className="max-w-[1450px] m-auto">
          <Header />
          <div className="md:px-[60px] px-[10px]">
            <div className="max-w-[1100px]  m-auto">
              <p className="md:text-[64px] text-[20px] font-bold text-center md:leading-[73px] leading-[30px] text-white mt-[60px] uppercase">
                Discover Exclusive Crypto Opportunities with Narrow Launchpad
              </p>
              <p className=" text-white text-center md:text-[20px] text-[16px] font-[400] mt-[25px] max-w-[600px] m-auto">
                Participate in upcoming IDOs and claim tokens with privacy keys
                across BSC, ETH, and Arbitrum chains.
              </p>
              <div className="sm:flex justify-center md:pb-28 pb-5 mt-6">
                <div className="h-[50px] cursor-pointer text-[20px] sm:max-w-[226px] w-[100%] bg-[#F206C2] rounded-full font-semibold  flex justify-center items-center text-white mr-5">
                  Connect Wallet
                </div>
                <div className="h-[50px] cursor-pointer text-[20px] sm:mt-0 mt-5 sm:max-w-[222px] w-[100%] border rounded-full font-semibold  flex justify-center items-center text-white">
                  Launch Pad List
                </div>
              </div>
            </div>

            <IDOS IDOSArray={IDOSArray} title="Upcoming IDOs" />
            <div className="md:mt-[210px] mt-[20px]">
              <IDOS IDOSArray={IDOSArray} title="PREVIOUS IDOs" />
            </div>
            <HowItWork />
            <Partners />
            <Team />
            <FAQ />
            <JoinTelegram />
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
