import React from "react";
import FeatureAirDrops from "../../components/featureAirDrops";
import HowItWork from "../../components/howItWork";
import Partners from "../../components/partners";
import Team from "../../components/team";
import FAQ from "../../components/faq";
import JoinTelegram from "../../components/joinTelegram";
import Footer from "../../components/footer";
import Header from "../../components/header";
import OurMission from "../../components/mission";
import HowWeAre from "../../components/howWeAre";

export default function AboutUs() {
  const aboutArray = [
    {
      img: "/image 9.png",
      title: "Transparency",
      des: "At Narrow Protocol, transparency is not just a commitment; it's our cornerstone.",
    },
    {
      img: "/image 10.png",
      title: "Team Experience",
      des: "At Narrow Protocol, transparency is not just a commitment; it's our cornerstone.",
    },
    {
      img: "/image 11.png",
      title: "Security Guarantee",
      des: "At Narrow Protocol, transparency is not just a commitment; it's our cornerstone.",
    },
  ];
  return (
    <div>
      <div className="bg-no-repeat bg-cover about">
        <div className="max-w-[1450px] m-auto">
          {" "}
          <Header />
          <div className="xl:px-[60px] px-[10px]">
            <div className="max-w-[826px]  m-auto">
              <p className="md:text-[64px] text-[30px] font-bold text-center md:leading-[73px] leading-[40px] text-white mt-[70px] uppercase">
                Empowering Privacy in the Crypto Space
              </p>
              <p className=" text-white text-center md:text-[20px] text-[16px] font-[400] mt-[30px]">
                Unveil the Story Behind Narrow Protocol
              </p>
              <div className="sm:flex justify-center md:pb-28 pb-5 mt-7">
                <div className="h-[50px] cursor-pointer text-[20px] sm:max-w-[226px] w-[100%] bg-[#F206C2] rounded-full font-semibold  flex justify-center items-center text-white mr-5">
                  Connect Wallet
                </div>
                <div className="h-[50px] cursor-pointer text-[20px] sm:mt-0 mt-5 sm:max-w-[222px] w-[100%] border rounded-full font-semibold  flex justify-center items-center text-white">
                  Launch Pad List
                </div>
              </div>
            </div>
            <div className="md:pt-10 pt-5">
              <OurMission />
            </div>
            <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 md:mt-[143px] mt-[20px] lg:gap-10 gap-5">
              {aboutArray.map((item) => (
                <div className="bg-[#C4C4C41A] rounded-lg py-[12px] px-[15px] border-2 border-[#383666]">
                  <div className="bg-[#0D1167] px-[32px] py-[35px] min-h-[322px] h-[100%] flex justify-between flex-col rounded-lg">
                    <img src={item?.img} alt="" className="w-[63px] h-[71px]" />
                    <p className="text-white font-[600] text-[28px]">
                      {item.title}
                    </p>
                    <p className="text-white text-[18px]">{item.des}</p>
                  </div>
                </div>
              ))}
            </div>
            <HowWeAre />
            <Partners />
            <Team />
            <FAQ />
            <JoinTelegram />
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
