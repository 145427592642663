import React from "react";
import Header from "../../components/header";
import FeatureAirDrops from "../../components/featureAirDrops";
import HowItWork from "../../components/howItWork";
import Partners from "../../components/partners";
import Team from "../../components/team";
import FAQ from "../../components/faq";
import JoinTelegram from "../../components/joinTelegram";
import Footer from "../../components/footer";

export default function Discover() {
  return (
    <div>
      <div className="bg-no-repeat bg-cover discover">
        <div className="max-w-[1450px] m-auto">
          {" "}
          <Header />
          <div className="md:px-[60px] px-[10px]">
            <div className="max-w-[826px]  m-auto">
              <p className="md:text-[64px] text-[30px] font-bold text-center md:leading-[73px] leading-[40px] text-white mt-[70px] uppercase">
                Explore Exclusive Airdrops
              </p>
              <p className=" text-white text-center text-[20px] font-[400] mt-[30px]">
                Uncover exciting airdrop opportunities and be among the first to
                claim tokens from upcoming projects. Dive into the world of
                decentralized finance with Narrow Protocol's curated airdrop
                selections.
              </p>
              <div className="sm:flex block justify-center sm:pb-28 pb-5 mt-7">
                <div className="h-[50px] cursor-pointer text-[20px] sm:max-w-[226px] w-[100%] bg-[#F206C2] rounded-full font-semibold  flex justify-center items-center text-white mr-5">
                  Connect Wallet
                </div>
                <div className="h-[50px] cursor-pointer text-[20px] sm:max-w-[222px] sm:mt-0 mt-3 w-[100%] border rounded-full font-semibold  flex justify-center items-center text-white">
                  Launch Pad List
                </div>
              </div>
            </div>
            <FeatureAirDrops />
            <HowItWork />
            <Partners />
            <Team />
            <FAQ />
            <JoinTelegram />
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
