import React from "react";

export default function CryptoFeatures() {
  const cryptoArray = [
    {
      img: "/group.png",
      title: "Privacy Launchpad",
      des: "Our launchpad ensures privacy by allowing users to participate in IDOs without exposing their wallet addresses.",
    },
    {
      img: "/group-1.png",
      title: "Chain Compatibility",
      des: "Works seamlessly on Solana, BSC, ETH, and Arbitrum chains.",
    },
    {
      img: "/group-2.png",
      title: "Future Expansion",
      des: "We're not done yet. Narrow Protocol will introduce new ground-breaking utilities in the future.",
    },
    {
      img: "/group-3.png",
      title: "Use Phantom Wallet",
      des: "A friendly Solana wallet build for Defi, NFTs and Trading!",
    },
  ];
  return (
    <div>
      <div className="md:mt-[250px] mt-[50px] text-white">
        <p className="text-[54px] font-bold max-w-[1026px] m-auto text-center leading-[61px]">
          COMBINE THE BEST CRYPTO FEATURES
        </p>
        <div className="grid md:grid-cols-2 md:mt-[81px] mt-[30px]">
          {cryptoArray.map((item) => (
            <div className="lg:flex mt-5">
              <img src={item?.img} alt="" className="h-fit" />
              <div className="md:ml-[30px]">
                <p className="text-[32px] font-[600]">{item.title}</p>
                <p className="text-[18px] max-w-[450px]">{item?.des}</p>
                <div className="flex items-center mt-[20px]">
                  <p className="text-[18px] font-[500]">Discover More</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 text-white ml-3"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                    />
                  </svg>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="md:mt-[255px] mt-[50px]">
          <div className="grid grid-cols-12 gap-5">
            <div className="md:col-span-5 col-span-12">
              <p className="text-[40px] font-bold text-center">
                Discover Airdrops
              </p>
              <p className="text-[18px] leading-[20px] max-w-[498px] m-auto text-center mt-2">
                Explore exciting airdrop opportunities and learn the
                requirements to farm airdrops on Narrow Protocol.
              </p>
              <div className="bg-[#C4C4C41A] rounded-lg border-2 border-[#383666] p-[20px]  w-[100%] mt-8 ">
                <img src="/seating-4.png" alt="" className="w-[100%]" />
              </div>
            </div>
            <div className="col-span-2 md:block hidden">
              <div className="flex justify-center items-center h-[100%]">
                <img src="/image 7.png" alt="" className="mt-[100px]" />
              </div>
            </div>
            <div className="md:col-span-5 col-span-12">
              <p className="text-[40px] font-bold text-center">
                Token Requirement
              </p>
              <p className="text-[18px] leading-[20px] max-w-[498px] m-auto text-center mt-2">
                Hold a certain amount of Narrow tokens in your wallet to unlock
                and utilize the Discover Airdrops function
              </p>
              <div className="bg-[#C4C4C41A] rounded-lg border-2 border-[#383666] p-[20px]  w-[100%] mt-8">
                <img src="/seating-5.png" alt="" className="w-[100%]" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
