import "./App.css";
import Discover from "./pages/discover";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LounchPad from "./pages/lounchPad";
import AboutUs from "./pages/aboutUs";
import Home from "./pages/home";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/discover" element={<Discover />} />

          <Route path="/launch-pad" element={<LounchPad />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
