import React from "react";

function Timeline() {
  return (
    <div className="container  mx-auto w-full h-full mt-[153px]">
      <div>
        <p className="text-[48px] font-bold text-white max-w-[501px] leading-[54px] m-auto text-center">
          How Narrow Launchpad Works
        </p>
      </div>
      <div className="relative wrap overflow-hidden md:p-10 h-full sm:mt-0 mt-5">
        <div className="border-2-2 absolute  border-[#383666] h-full border sm:left-[50%] left-[6%] "></div>

        {/* Right timeline */}
        <div className="mb-20 flex sm:justify-between items-center w-full right-timeline">
          <div className="order-1 sm:w-5/12"></div>
          <div className="z-20 flex items-center order-1 bg-[#383666] shadow-xl w-8 h-8 rounded-full">
            <h1 className="mx-auto font-semibold text-lg text-white">1</h1>
          </div>
          <div className="order-1 bg-[#C4C4C41A] border-2 border-[#383666] rounded-lg sm:w-5/12 w-[95%] px-6 py-4">
            <h3 className="mb-3 font-bold text-white text-xl">
              Connect Your Wallet
            </h3>
            <p className="text-sm leading-snug tracking-wide text-[#D0D1DC] text-opacity-100">
              Begin by connecting your preferred wallet to Narrow Protocol. We
              support wallets compatible with BSC, ETH, and Arbitrum. This step
              ensures a secure and seamless experience throughout the launchpad
              process.
            </p>
          </div>
        </div>

        {/* Left timeline */}
        <div className="mb-20 flex sm:justify-between sm:flex-row-reverse items-center w-full left-timeline">
          <div className="order-1 sm:w-5/12"></div>
          <div className="z-20 flex items-center order-1 bg-[#383666] shadow-xl w-8 h-8 rounded-full">
            <h1 className="mx-auto text-white font-semibold text-lg">2</h1>
          </div>
          <div className="order-1 bg-[#C4C4C41A] border-2 border-[#383666] rounded-lg sm:w-5/12 w-[95%] px-6 py-4">
            <h3 className="mb-3 font-bold text-white text-xl">
              Explore Upcoming IDOs
            </h3>
            <p className="text-sm font-medium leading-snug tracking-wide text-[#D0D1DC] text-opacity-100">
              Browse through the list of upcoming Initial DEX Offerings (IDOs)
              featured on Narrow Protocol. Get detailed information about each
              project, including token details, launch dates, and project
              summaries.
            </p>
          </div>
        </div>

        {/* Right timeline */}
        <div className="mb-20 flex sm:justify-between items-center w-full right-timeline">
          <div className="order-1 sm:w-5/12"></div>
          <div className="z-20 flex items-center order-1 bg-[#383666] shadow-xl w-8 h-8 rounded-full">
            <h1 className="mx-auto font-semibold text-lg text-white">3</h1>
          </div>
          <div className="order-1 bg-[#C4C4C41A] border-2 border-[#383666] rounded-lg shadow-xl sm:w-5/12 w-[95%] px-6 py-4">
            <h3 className="mb-3 font-bold text-[white] text-xl">
              Participate with Privacy Keys
            </h3>
            <p className="text-sm leading-snug tracking-wide text-[#D0D1DC] text-opacity-100">
              Protect your privacy while participating in IDOs. Use Narrow
              Protocol's privacy keys to engage in token sales without exposing
              your actual wallet address.
            </p>
          </div>
        </div>

        {/* Left timeline */}
        <div className="mb-20 flex sm:justify-between sm:flex-row-reverse items-center w-full left-timeline">
          <div className="order-1 sm:w-5/12"></div>
          <div className="z-20 flex items-center order-1 bg-[#383666] shadow-xl w-8 h-8 rounded-full">
            <h1 className="mx-auto text-white font-semibold text-lg">4</h1>
          </div>
          <div className="order-1 bg-[#C4C4C41A] border-2 border-[#383666] rounded-lg sm:w-5/12 w-[95%] px-6 py-4">
            <h3 className="mb-3 font-bold text-white text-xl">
              Claim Your Tokens
            </h3>
            <p className="text-sm font-medium leading-snug tracking-wide text-[#D0D1DC] text-opacity-100">
              After successful participation, claim your tokens hassle-free. Our
              user-friendly interface makes it easy to manage and transfer your
              acquired tokens directly to your connected wallet.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Timeline;
