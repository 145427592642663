import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function FeatureAirDrops() {
  const sliderRef = useRef(null);
  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1324,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };
  const discoverArray = [
    {
      img: "/image-1.png",
      title: "CryptoGenius",
      des: "Learn about this project and discover the requirements to participate in their airdrop. Don't miss out on this limited-time opportunity!",
    },
    {
      img: "/5.png",
      title: "EtherVibes",
      des: "Discover EtherVibes, the second featured airdrop. Learn about the project and find out how you can get involved.",
    },
    {
      img: "/6.png",
      title: "QuantumMesh",
      des: "Delve into QuantumMesh, the third featured airdrop. Explore the details of the project and follow the steps to start earning tokens.",
    },
  ];
  return (
    <div>
      <p className="font-bold md:text-[54px] text-[30px] text-center text-white sm:mt-16 mt-[20px]">
        Featured Airdrops
      </p>
      <div className=" mt-14 ">
        <Slider {...settings} ref={sliderRef}>
          {discoverArray.map((item, index) => (
            <div
              key={index}
              className="lg:!w-[400px] sm:!w-[300px] !w-[100%] py-[15px] px-[20px] bg-[#211448] rounded-2xl"
              style={{ backdropFilter: "blur(5px)" }}
            >
              <img src={item.img} alt="" className="w-[100%]" />
              <p className="font-semibold text-[24px] mt-3 text-white">
                {item?.title}
              </p>
              <p className="text-white text-[18px] mt-3">{item?.des}</p>
            </div>
          ))}
        </Slider>
      </div>
      <div className="max-w-[330px] flex justify-between items-center m-auto  sm:mt-28 mt-5">
        <div
          onClick={handlePrev}
          className="w-[53px] h-[53px] rounded-full border border-[#F206C2] cursor-pointer flex justify-center items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5 8.25 12l7.5-7.5"
            />
          </svg>
        </div>
        <p className=" font-bold text-white">VIEW ALL</p>
        <div
          onClick={handleNext}
          className="w-[53px] h-[53px] rounded-full border border-[#F206C2] cursor-pointer flex justify-center items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}
