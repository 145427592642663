import React from "react";

export default function HowItWork() {
  return (
    <div>
      <p className="text-white font-bold md:text-[54px] text-[30px] text-center md:mt-28 mt-5 uppercase">
        How It works
      </p>
      <p className="font-bold md:text-[32px] text-[20px] text-white text-center max-w-[600px] m-auto leading-[40px] md:mt-16 mt-5">
        WHAT IS NARROW PROTOCOL AND WHY SHOULD YOU CARE?
      </p>
      <div className="grid grid-cols-12 lg:gap-20 md:gap-10 gap-5 mt-16">
        <div className="sm:col-span-5 col-span-12">
          <div className="bg-[#C4C4C41A] rounded-lg p-[15px] border-2 border-[#383666]">
            <img src="/seating.png" alt="" className="w-full" />
          </div>
        </div>
        <div className="md:col-span-7 col-span-12">
          <p className="text-white md:mt-10 mt-5">
            Narrow Protocol is the first Privacy-Focused Launchpad and Airdrop
            Discovery platform built on the Solana Ecosystem.
          </p>
          <p className="text-white text-[28px] font-[600] mt-4">Why Narrow?</p>
          <p className="text-white mt-4">
            Users who participate in IDOs and ICOs on token launchpad platforms
            are unfairly exposed - with everyone having the ability to trace and
            track each user’s wallets. This significantly reduces the privacy
            that launchpad users are entitled to and exposes them to several
            risks.
          </p>
          <p className="text-white text-[28px] font-[600] mt-4">
            Narrow solves this
          </p>
          <p className="text-white mt-4">
            Narrow also offers another major utility - which is the Discover
            Airdrop feature. With DA, users that hold a portion of Narrow Token
            are able to connect their wallet address to the platform and
            discover the most profitable airdrops about to happen, as well as
            the tasks that are required to be completed to be eligible for such
            airdrops.
          </p>
          <p className="text-white mt-5">
            This will significantly increase the opportunities airdrop hunters
            see and put more crypto airdrop in their wallets.
          </p>
        </div>
      </div>
    </div>
  );
}
