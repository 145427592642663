import React, { useState } from "react";
import { FaqsData } from "../../data";

export default function FAQ() {
  const [active, setActive] = useState({
    open: false,
    indexOpen: "",
  });
  const toggleActive = (index) => {
    if (index === active.indexOpen) {
      setActive({ open: false, indexOpen: "" });
    } else {
      setActive({ open: true, indexOpen: index });
    }
  };
  return (
    <div>
      <p className="text-white md:text-[48px] text-[30px] font-bold text-center max-w-[464px] m-auto md:leading-[54px] leading-[30px] md:mt-[118px] mt-[20px]">
        FREQUENTLY ASKED QUESTIONS
      </p>
      <div className="">
        {FaqsData.map((item, index) => (
          <div
            key={index}
            className="mt-7 cursor-pointer bg-[#141449] py-[20px] md:px-[40px] px-[10px] border-2 border-[#4c4c73] rounded-lg "
          >
            <div
              onClick={() => toggleActive(index)}
              className="flex  justify-between items-center"
            >
              <p className="text-white px-5 sm:text-[22px] text-base font-[700]">
                {item.question}
              </p>
              <div className="w-[40px] h-[40px] rounded-full border-2 border-[#4c4c73] flex justify-center items-center bg-[#46466f]">
                {active.open && active.indexOpen === index ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M5 12h14"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 4.5v15m7.5-7.5h-15"
                    />
                  </svg>
                )}
              </div>
            </div>
            {active.open && active.indexOpen === index ? (
              <div className="flex py-4">
                <p className="border-l- px-5 text-[18px]   text-[#888d97] font-[400]">
                  {item.ans}
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
