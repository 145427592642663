export const FaqsData = [
  {
    id: "1",
    question: "What is Narrow Protocol?",
    ans: "Narrow Protocol aims to provide privacy to Launchpad and streamline access to billions of dollars in airdrop to its users. Narrow is the first project to bring this combined utility to crypto. Narrow will bring its powerful launchpad across several blockchains including Solana, BSC, and Eth.",
  },
  {
    id: "2",
    question: "How do I get started with Narrow Protocol?",
    ans: "Getting started with NarrowProtocol is easy. Once you have purchased your $Narrow Token - you'll have access to our Discover Airdrop tool, which will give you access to our dashboard of the latest airdrops and the steps required to start farming. We have locked in over 30 upcoming airdrops worth 10s of thousands of dollars for each user.",
  },
  {
    id: "3",
    question: "Is Narrow Protocol available on the Solana Network?",
    ans: "Narrow Protocol is going to be launching on Solana Network in its initial phase, as well as the BSC chain. Other blockchains would be introduced to expand our network.",
  },
  {
    id: "4",
    question: "What is the Utility Behind Narrow Token?",
    ans: "Our privacy-enabled launchpad which allows you to safely participate in IDOs and ICOs, as well as our Discover Airdrop platform are the utilities behind the Narrow Token. The narrow token ticker is $Narrow.",
  },
  {
    id: "5",
    question: "What is the Discover Airdrops feature?",
    ans: "Discover Airdrop is an industry-first utility that allows Narrow holders to find high-value airdrop opportunities before anyone else and gives them an advantage by showing the steps required to qualify for the airdrops. The Discover Airdrop is AI-led and has been tested to eradicate errors.",
  },
];
