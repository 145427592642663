import React from "react";

export default function Partners() {
  return (
    <div>
      <p className="font-bold md:text-[32px] text-[25px] text-white md:mt-[148px] mt-[20px]">
        Our Trading Partners
      </p>
      <div className="flex justify-between flex-wrap md:mt-[70px] mt-5">
        <a href="https://pinksale.finance"><img src="/image.png" alt="" className="m-auto mt-2" /></a>
        <a href="https://dexview.com"><img src="/image-2.png" alt="" className="m-auto mt-2" /></a>
         <a href=""><img src="/image-3.png" alt="" className="m-auto mt-2" /></a>
        <a href=""><img src="/image-4.png" alt="" className="m-auto" /></a>
      </div>
    </div>
  );
}
