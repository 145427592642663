// Drawer.js

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Drawer = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen1, setIsOpen1] = useState(false);
  const toggleMenu = () => {
    setIsOpen1(!isOpen1);
  };
  return (
    <div
      className={`fixed inset-0 overflow-hidden z-50 ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="fixed inset-0 transition-opacity" onClick={onClose}>
        <div className="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 outline-none">
        <div className="w-screen max-w-xs">
          <div className="h-full flex flex-col py-6 bg-[#25087a] shadow-xl overflow-y-scroll">
            <div className="px-4 sm:px-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 text-white"
                onClick={onClose}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>

              <div className="mt-1 border-t border-gray-200"></div>
            </div>
            <div className="mt-6 relative flex-1 px-4 sm:px-6">
              {/* Content of the drawer */}
              <div className=" w-[100%] xl:text-[18px] text-[14px] xl:px-[60px]  px-[20px]">
                <p
                  className={`font-semibold mt-3  ${
                    location.pathname === "/" ? "text-[#F206C2]" : "text-white"
                  }`}
                  onClick={() => navigate("/")}
                >
                  Home
                </p>
                <p
                  className={`font-semibold  mt-3 ${
                    location.pathname === "/about-us"
                      ? "text-[#F206C2]"
                      : "text-white"
                  }`}
                  onClick={() => navigate("/about-us")}
                >
                  About us
                </p>
                <p
                  className={`font-semibold mt-3  ${
                    location.pathname === "/launch-pad"
                      ? "text-[#F206C2]"
                      : "text-white"
                  }`}
                  onClick={() => navigate("/launch-pad")}
                >
                  Launchpad
                </p>
                <p
                  className={`font-semibold mt-3 ${
                    location.pathname === "/discover"
                      ? "text-[#F206C2]"
                      : "text-white"
                  }`}
                  onClick={() => navigate("/discover")}
                >
                  Discover Airdrops
                </p>
                <p className="font-semibold  text-white mt-3">BUY NARROW</p>
                <p className="font-semibold  text-white mt-3">CHART</p>
              </div>
              <div
                onClick={toggleMenu}
                className="h-[54px] flex cursor-pointer mt-3 text-[20px] max-w-[228px] w-[100%] bg-[#F206C2] rounded-full font-semibold   justify-center items-center text-white"
              >
                Connect Wallet
              </div>
              {isOpen1 && (
                <div className="relative mt-[20px]">
                  <div
                    className={` bg-[#F206C2] z-20 p-[40px] max-w-[394px] w-[100%] rounded-lg shadow-md  ${
                      isOpen1 ? "" : "hidden"
                    }`}
                  >
                    <div className="flex justify-between">
                      <p className="font-semibold text-[20px] text-white">
                        Connect Wallet
                      </p>{" "}
                      <div
                        className="w-[23px] h-[23px] rounded-full bg-[white] cursor-pointer flex justify-center items-center"
                        onClick={toggleMenu}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-3 h-3"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18 18 6M6 6l12 12"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="w-[100%] py-[24px] px-[32px] bg-white rounded-2xl">
                      <p className="font-bold">Choose your preferred wallet</p>
                      <div className="flex justify-between mt-[29px]">
                        <p className="font-bold text-[20px]">Rainbow</p>{" "}
                        <img src="/1.png" alt="" />
                      </div>
                      <div className="flex justify-between mt-[29px]">
                        <p className="font-bold text-[20px]">MetaMask</p>{" "}
                        <img src="/2.png" alt="" />
                      </div>
                      <div className="flex justify-between mt-[29px]">
                        <p className="font-bold text-[20px]">Gnosis Safe</p>{" "}
                        <img src="/3.png" alt="" />
                      </div>
                      <div className="flex justify-between mt-[29px]">
                        <p className="font-bold text-[20px]">Argent</p>{" "}
                        <img src="/4.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drawer;
